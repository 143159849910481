
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import ProductMasterSidebar from '@/components/product-master-sidebar.vue';
import productService from '@/services/product-service';
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    Loader,
    ProductMasterSidebar,
    PopUp
  },
})
export default class CategoryLevel1 extends Vue {
  public fields = [{ key: 'title', sortable: true }, { key: 'actions', label: 'Actions' }];
  loading = true;
  filter = null;
  filterOn = [];
  error_mesg = null;
  public items = [];
  private object: any = { title: "" };

  public currentPage = 1;
  get rows() { return this.items.length; }
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  retrieve() {
    this.loading = true;
    productService.get_sub_level_1_category('').then((response) => {
      this.items = response.data;
      this.loading = false;
    })
      .catch((e) => {
        this.loading = false;
      });
  }

  handleSubmit() {

    this.create();
  }
  create() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.object, ['title'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    } else {
      this.loading = true;
      // this.error_mesg = null;
      if (this.object['id'] != null) {
        productService.update_sub_level_1_category(this.object['id'], this.object)
          .then((response) => {
            if (response) {
              this.retrieve();
              this.object = { title: "" }
              this.loading = false;
              this.showModal("Updated Successfully", [], "success")
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log('Unexpected error:', e);
            this.showModal('An unexpected error occurred', [], "error");
          });
      } else {
        productService.create_sub_level_1_category(this.object)
          .then((response) => {
            if (response) {
              this.retrieve();
              this.object = { title: "" }
              this.loading = false;
              this.showModal("Created Successfully", [], "success")
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log('Unexpected error:', e);
            this.showModal('An unexpected error occurred', [], "error");
          });
      }
    }
  }
  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    // this.rows = filteredItems.length
    // this.currentPage = 1
  }

  delete(id: any) {
    this.loading = true;
    productService.delete_sub_level_1_category(id)
      .then((response) => {
        if (response) {
          this.retrieve();
          this.object = { id: null, title: "" }
          this.loading = false;
          this.showModal("Deleted Successfully", [], "success")
        }
      })
      .catch((e) => {
        // let eror = e.response.data;
        // let r = eror[Object.keys(eror)[0]];
        // this.error_mesg = r;
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }

  info(row: any) {
    this.object['id'] = row['id'];
    this.object['title'] = row['title'];
  }
  removeinfo(id: any) {
    this.delete(id);
  }
  mounted() {
    this.retrieve();
  }
  reset() {
    this.object = {};
    this.error = "";
  }
}
